<template>
  <div class="p-4">
    <Breadcrumb v-show="false"></Breadcrumb>
    <Heading content="STORIES">
      <el-button type="primary" slot="button" size="small" @click="addStories">
        <i class="fa fa-plus"></i>
      </el-button>
    </Heading>
    <hr />
    <div class="text-center mb-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="page"
        @current-change="changePage"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-table
      class="mb-4"
      :data="stories"
      border
      style="width: 100%"
      height="600"
    >
      <el-table-column prop="sort" label="Sort" width="80">
        <template slot-scope="scope">
          <kbd>{{  scope.row.sort }}</kbd>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="User" width="180"></el-table-column>
      <el-table-column prop="title" label="Title">
        <el-table-column label="en">
          <template slot-scope="scope">
            {{ scope.row.title["en"] }}
          </template>
        </el-table-column>
        <el-table-column label="tw">
          <template slot-scope="scope">
            {{ scope.row.title["tw"] }}
          </template>
        </el-table-column>
        <el-table-column label="cn">
          <template slot-scope="scope">
            {{ scope.row.title["cn"] }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="feedback" label="Feedback">
        <el-table-column label="en">
          <template slot-scope="scope">
            {{ scope.row.feedback["en"] }}
          </template>
        </el-table-column>
        <el-table-column label="tw">
          <template slot-scope="scope">
            {{ scope.row.feedback["tw"] }}
          </template>
        </el-table-column>
        <el-table-column label="cn">
          <template slot-scope="scope">
            {{ scope.row.feedback["cn"] }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="feedback_src" label="Feedback Image" width="160">
        <template slot-scope="scope">
          <el-image
            style="width: 100px;"
            :src="scope.row.feedback_src"
            :preview-src-list="[scope.row.feedback_src]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="Actions" width="160">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editStories(scope.row)">
            <i class="fa fa-edit"></i>
          </el-button>
          <el-button
            type="danger"
            size="mini"
            @click="removeStory(scope.row.id)"
          >
            <i class="fa fa-trash-alt"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-4">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="page"
        @current-change="changePage"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="Edit Stories"
      v-if="activeStory"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="70%"
    >
      <el-form ref="form" :model="activeStory" label-width="180px">
        <el-form-item label="Sort">
          <el-input v-model="activeStory.sort" type="number"></el-input>
        </el-form-item>
        <el-form-item label="Name">
          <el-input v-model="activeStory.name"></el-input>
        </el-form-item>
        <el-form-item label="Title(EN)">
          <el-input v-model="activeStory.title.en"></el-input>
        </el-form-item>
        <el-form-item label="Title(TW)">
          <el-input v-model="activeStory.title.tw"></el-input>
        </el-form-item>
        <el-form-item label="Title(CN)">
          <el-input v-model="activeStory.title.cn"></el-input>
        </el-form-item>
        <el-form-item label="Feedback(EN)">
          <el-input
            v-model="activeStory.feedback.en"
            :rows="3"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="Feedback(TW)">
          <el-input
            v-model="activeStory.feedback.tw"
            :rows="3"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="Feedback(CN)">
          <el-input
            v-model="activeStory.feedback.cn"
            :rows="3"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="Feedback Image">
          <el-image
            v-show="activeStory.feedback_src"
            style="width: 100px;"
            :src="activeStory.feedback_src"
            :preview-src-list="[activeStory.feedback_src]"
          >
          </el-image>
          <el-upload
            ref="upload"
            drag
            name="file"
            :before-upload="beforeAvatarUpload"
          >
            <div
              slot="tip"
              class="el-upload__tip m-0 mb-2"
              style="line-height: 1rem;"
            >
              <b>{{
                $t(
                  "flashCards.Images must be in jpg or png format and cannot be bigger than 5 mb"
                )
              }}</b>
              <br />
            </div>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              {{ $t("flashCards.Drag and drop image/video or upload") }}
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" v-if="activeStory.id" @click="updateStory">
          Save
        </el-button>
        <el-button type="primary" v-else @click="addStory">
          Add
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import StoriesApi from "@/apis/stories";
import FlashCards from "@/apis/flashcards";
import heic2any from "heic2any";
import mixins from "@/views/flashCards/mixins/index.js";

export default {
  metaInfo() {
    return {
      title: "Stories - Ivy-Way Academy"
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [mixins],

  props: [],
  data() {
    return {
      dialogVisible: false,
      pageSize: 6,
      total: 0,
      stories: null,
      activeStory: null
    };
  },
  computed: {
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    this.getStories();
  },

  methods: {
    beforeAvatarUpload(file) {
      let fileType = this.checkFile(file.name);
      const isLt5M = file.size / 1024 / 1024 < 5;
      const isImage = fileType === "image";
      const isVideo = fileType === "video";
      if (!(isImage || isVideo)) {
        this.$message.error(
          this.$t(
            "flashCards.Please upload the image or video in the right format!"
          )
        );
      }
      if (!isLt5M) {
        this.$message.error(
          this.$t("flashCards.The image or video cannot be bigger than 5 mb!")
        );
      }
      let canUpload = false;
      canUpload = (isImage || isVideo) && isLt5M;
      if (canUpload) {
        this.upLoading = true;
        this.getImg(file);
      }
      return false;
    },
    getImg(file) {
      let fileNameExt = file.name.substr(file.name.lastIndexOf(".") + 1);
      let fileName = file.name.substring(0, file.name.lastIndexOf("."));
      if (fileNameExt == "heic" || fileNameExt == "HEIC") {
        heic2any({
          blob: new Blob([file]), // 将heic转换成一个buffer数组的图片
          toType: "image/jpeg", //要转化成具体的图片格式，可以是png/gif
          quality: 0.4 // 图片的质量，参数在0-1之间
        }).then(result => {
          let formData = new FormData();
          formData.append("file", result, fileName + ".jpeg");
          this.uploadJpg(formData);
        });
      } else {
        let formData = new FormData();
        formData.append("file", new Blob([file]), file.name);
        this.uploadJpg(formData);
      }
    },
    async uploadJpg(formData) {
      const res = await FlashCards.uploadImg(formData);
      this.activeStory.feedback_src = res.url;
    },
    editStories(story) {
      this.activeStory = null;
      this.dialogVisible = true;
      this.activeStory = JSON.parse(JSON.stringify(story));
    },
    addStories() {
      this.activeStory = null;
      this.activeStory = {
        sort: null,
        name: "",
        feedback: {
          en: "",
          tw: "",
          cn: ""
        },
        is_teacher: false,
        state: 1,
        feedback_src: null,
        title: {
          en: "",
          tw: "",
          cn: ""
        }
      };
      this.dialogVisible = true;
    },
    changePage(page) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page
        }
      });
    },
    async getStories() {
      const res = await StoriesApi.getStories({
        page: this.page
      });
      this.stories = res.data;
      this.total = res.total;
    },
    async removeStory(id) {
      this.$confirm(
        "Are you sure you want to delete this story? This cannot be undone.",
        this.$t("newCourses.Confirmation"),
        {
          confirmButtonText: this.$t("newCourses.Confirm"),
          cancelButtonText: this.$t("newCourses.Cancel"),
          type: "error"
        }
      ).then(async () => {
        await StoriesApi.removeStory(id);
        this.getStories();
      });
    },
    async addStory() {
      await StoriesApi.addStory(this.activeStory);
      this.getStories();
      this.dialogVisible = false;
    },
    async updateStory() {
      if (this.activeStory.id) {
        await StoriesApi.updateStory(this.activeStory.id, this.activeStory);
        this.getStories();
        this.dialogVisible = false;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-dialog__body {
  height: 500px;
  overflow: auto;
}
</style>
